// TODO: remove websdk
import { AppProvider, TOKEN } from "@stader-labs/web-sdk";

import Services from "./wagmi";
import { ReactNode } from "react";
import useDappChain from "@hooks/useDappChain";
import { useUtmRefereal } from "@hooks/useUtm";

const SDKProvider = ({ children }: { children: ReactNode }) => {
  const { chain } = useDappChain();
  useUtmRefereal();

  return (
    <AppProvider token={TOKEN.RSETH} chainId={chain.id}>
      <Services>{children}</Services>
    </AppProvider>
  );
};

export default SDKProvider;
