import { useEffect, useState } from "react";
import { useAccount, useConnect, useConnectors } from "wagmi";

const useLedgerLive = () => {
  const connectors = useConnectors();
  const { connect } = useConnect();
  const { connector } = useAccount();

  const [isLedgerInjected, setIsLedgerInjected] = useState(false);

  useEffect(() => {
    setIsLedgerInjected(window?.ethereum?.isLedgerLive || false);
  }, [connect, connectors]);

  const isLedgerConnected = connector?.id === "com.ledger";

  const isLedgerLiveENV = isLedgerInjected && isLedgerConnected;

  return { isLedgerConnected, isLedgerInjected, isLedgerLiveENV };
};

export default useLedgerLive;
