import { InputGroup, Input, InputRightElement, Button } from "@chakra-ui/react";
import { SyntheticEvent } from "react";

interface EmailProps {
  value: string;
  onChange: (value: string) => void;
  handleSubmit: (event: SyntheticEvent) => void;
  isDisabled: boolean;
}

const SubscribeEmail = ({
  value,
  isDisabled,
  onChange,
  handleSubmit,
}: EmailProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <InputGroup size="lg">
        <Input
          type="email"
          variant="unstyled"
          placeholder="Enter email"
          borderRadius="1rem"
          bg="#FFF"
          border="1px solid"
          borderColor="backgroundDivider"
          p="12px"
          h="68px"
          color="textPrimary"
          fontWeight="500"
          fontSize="14px"
          lineHeight="22px"
          value={value}
          onChange={(e) => {
            onChange(e?.target?.value);
          }}
          required
        />

        <InputRightElement my="auto" h="100%" mr="12px" w="auto">
          <Button
            bg="textPrimary"
            size="sm"
            p="10px 28px"
            borderRadius="12px"
            fontWeight="500"
            fontSize="14px"
            lineHeight="24px"
            color="#FFF"
            h="44px"
            type="submit"
            isDisabled={isDisabled}
            _hover={{
              bg: "textPrimary",
            }}
          >
            Sign up
          </Button>
        </InputRightElement>
      </InputGroup>
    </form>
  );
};

export default SubscribeEmail;
