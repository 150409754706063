import { useRouter } from "next/router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateUserData } from "store";
import useLedgerLive from "./useLedgerLive";

type UTMParams = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
};

export function useUtm() {
  const router = useRouter();

  // Extract UTM parameters from the router query
  const utmParams: UTMParams = {};

  if (router.query.utm_source) {
    utmParams.utm_source = router.query.utm_source as string;
  }
  if (router.query.utm_medium) {
    utmParams.utm_medium = router.query.utm_medium as string;
  }
  if (router.query.utm_campaign) {
    utmParams.utm_campaign = router.query.utm_campaign as string;
  }

  return utmParams;
}

export function useUtmRefereal() {
  const utmParams = useUtm();
  const { isLedgerLiveENV } = useLedgerLive();
  const dispatch = useDispatch();

  useEffect(() => {
    const referralId = utmParams.utm_source
      ? utmParams.utm_source
      : isLedgerLiveENV
      ? "ledger_live"
      : "kelp_dao";

    dispatch(
      updateUserData({
        referralId: referralId,
      })
    );
  }, [utmParams, isLedgerLiveENV]);
}

export function useUtmLink(href: string) {
  const utmParams = useUtm();

  // Check if href is a full URL
  const isFullUrl = /^https?:\/\//.test(href);

  if (isFullUrl) {
    // If href is a full URL then parse it
    const url = new URL(href);

    const partialUrl = mergeParamsAndGetFullPartialURL(url, utmParams);

    return { isFullUrl, href: `${url.origin}${partialUrl}` };
  } else {
    // If href is a partial route (internal)
    const url = new URL(href, "https://kelpdao.xyz");
    const partialUrl = mergeParamsAndGetFullPartialURL(url, utmParams);
    return { isFullUrl, href: partialUrl };
  }
}

// Merge existing parameters with UTM parameters
function mergeParamsAndGetFullPartialURL(url: URL, utmParams: UTMParams) {
  const mergedParams = {
    ...Object.fromEntries(url.searchParams.entries()),
    ...utmParams,
  };

  // Create a URLSearchParams object
  const params = new URLSearchParams(mergedParams).toString();
  const searchParams = params ? `?${params}` : "";

  // If last char is not a slash, add it
  const pathname =
    url.pathname.endsWith("/") || url.pathname.includes(".pdf")
      ? url.pathname
      : `${url.pathname}/`;

  return `${pathname}${searchParams}${url.hash}`;
}
