import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Center } from "@chakra-ui/react";

import {
  isNumber,
  MAX_FRACTION_DIGITS,
  toFixedWithoutRounding,
  TransactionType,
  TXN_TYPES,
} from "../../constants/common";

import {
  CLAIM_TITLE_MAPPINGS,
  UNSTAKE_TITLE_MAPPINGS,
} from "../../constants/transactionMessages";

import { getChains } from "../../config";

import ModalDrawer from "@components/ModalDrawer";
import SuccessErrorModalBodyTemplate from "@components/SuccessErrorModalBodyTemplate";
import CloseIcon from "@components/CloseIcon";
import StakeSuccess from "@components/SuccessErrorModalBodyTemplate/StakeSuccess";
import useExchangeRate from "@hooks/useExchangeRate";
import { isFloat, toStringPrecision, trackEvent } from "utils";
import useDappConfig from "@hooks/useDappConfig";
import { useRouter } from "next/router";
import {
  POST_DEPOSIT_DEFI_EXPLORE,
  POST_VAULT_DEFI_EXPLORE,
} from "@constants/analytics";
// import VaultWithdraw from "@components/SuccessErrorModalBodyTemplate/VaultWitdraw";
import VaultDeposit from "@components/SuccessErrorModalBodyTemplate/VaultDeposit";
import VaultWithdraw from "@components/SuccessErrorModalBodyTemplate/VaultWitdraw";

interface TransactionModalProps {
  isOpen: boolean;
  hash: string;
  error: string;
  transactionType: string;
  isTxnProcessing: boolean;
  hasUserDenied: boolean;
  daysToWaitForWithdraw?: string;
  closeAlert: () => void;
  handleTxView: () => void;
  chainId: number;
  amount?: string | null;
}

let timerId: any = undefined;

const token = process.env.NEXT_PUBLIC_TOKEN || "";
const network = process.env.NEXT_PUBLIC_NETWORK || "0";

const TransactionModal = ({
  isOpen,
  hash,
  error,
  isTxnProcessing,
  hasUserDenied,
  transactionType,
  daysToWaitForWithdraw,
  chainId,
  amount,
  closeAlert,
  handleTxView,
}: TransactionModalProps) => {
  const [isTxnTakingMoreTime, setIsTxnTakingMoreTime] = useState(false);
  const router = useRouter();

  const conversionData = useExchangeRate();
  const { config } = useDappConfig();
  const conversions = useMemo(() => {
    const conversionToERC20 = conversionData?.exchangeRate;
    return {
      fromERC20: conversionToERC20 ? 1 / conversionToERC20 : 0,
    };
  }, [conversionData?.exchangeRate]);

  const convertedAmount = useMemo(() => {
    if (amount && isNumber(amount)) {
      const value = Number(amount) * Number(conversions.fromERC20);
      const formatedValue =
        value && isFloat(value)
          ? toFixedWithoutRounding(
              toStringPrecision(value),
              MAX_FRACTION_DIGITS
            )
          : toStringPrecision(value);
      if (!Number.isNaN(+formatedValue)) {
        return formatedValue;
      } else {
        return "0";
      }
    } else {
      return "0";
    }
  }, [amount, conversions]);

  const chainConfig = useMemo(() => {
    return getChains(network)[0];
  }, [token, network, chainId]);

  useEffect(() => {
    if (isTxnProcessing && hash) {
      timerId = setTimeout(() => {
        setIsTxnTakingMoreTime(true);
      }, 30000);
    }

    return () => clearTimeout(timerId);
  }, [isTxnProcessing, hash]);

  const getSuccessMessage = useCallback((transactionType: string) => {
    if (
      transactionType === TXN_TYPES.UNSTAKE &&
      UNSTAKE_TITLE_MAPPINGS[token?.toUpperCase()]
    ) {
      return UNSTAKE_TITLE_MAPPINGS[token?.toUpperCase()];
    } else if (
      transactionType === TXN_TYPES.CLAIM &&
      CLAIM_TITLE_MAPPINGS[token?.toUpperCase()]
    ) {
      return CLAIM_TITLE_MAPPINGS[token?.toUpperCase()];
    }
    return "Transaction Successful";
  }, []);

  const handleStakeSuccess = () => {
    trackEvent(POST_DEPOSIT_DEFI_EXPLORE, {
      cta_location: "primary",
    });

    router.push("/defi");
    closeAlert();
  };

  const handleVaultDepositSuccess = () => {
    trackEvent(POST_VAULT_DEFI_EXPLORE, {
      cta_location: "primary",
    });

    router.push("/defi");
    closeAlert();
  };

  const renderContent = () => {
    if (error) {
      if (hasUserDenied) {
        return (
          <SuccessErrorModalBodyTemplate
            transactionType={TransactionType.WARNING}
            modalSubTitle="User denied transaction signature. Please try again"
            modalTitle="Transaction denied"
          />
        );
      }
      return (
        <SuccessErrorModalBodyTemplate
          transactionType={TransactionType.ERROR}
          modalTitle="Transaction Failed"
          modalSubTitle={hash ? "" : error}
          primaryBtnTxt={hash ? "View transaction" : ""}
          onSubmitPrimary={handleTxView}
        />
      );
    } else if (hash) {
      if (isTxnProcessing) {
        if (isTxnTakingMoreTime) {
          return (
            <SuccessErrorModalBodyTemplate
              transactionType={TransactionType.WARNING}
              modalTitle="Transaction in-progress"
              modalSubTitle="Your transaction is taking longer than expected"
              primaryBtnTxt={
                chainConfig.blockExplorers?.default.name || "View Transaction"
              }
              onSubmitPrimary={handleTxView}
            />
          );
        } else {
          return (
            <SuccessErrorModalBodyTemplate
              isSpinnerRequired={true}
              modalTitle="Transaction in progress"
            />
          );
        }
      } else {
        // Successful
        if (transactionType === TXN_TYPES.STAKE) {
          return (
            <StakeSuccess
              transactionType={TransactionType.SUCCESS}
              modalTitle={getSuccessMessage(transactionType)}
              modalSubTitle={`${convertedAmount}  ${config.xtoken.symbol} minted.`}
              primaryBtnTxt="Explore DeFi"
              onSubmitPrimary={handleStakeSuccess}
              onTxView={handleTxView}
            />
          );
        } else if (transactionType === TXN_TYPES.VAULT_WITHDRAW) {
          return (
            <VaultWithdraw
              transactionType={TransactionType.SUCCESS}
              modalTitle="Withdrawal queued"
              onSubscribe={closeAlert}
              modalSubTitle="rsETH will be available to claim within 10 days."
              extraText="Stay in the Loop! Sign up with your email for the latest updates on what we are building at Kelp"
            />
          );
        } else if (transactionType === TXN_TYPES.VAULT_DEPOSIT) {
          return (
            <VaultDeposit
              modalTitle="Deposit Successful"
              modalSubTitle=""
              primaryBtnTxt="Explore agETH DeFi"
              onSubmitPrimary={handleVaultDepositSuccess}
              onTxView={handleTxView}
            />
          );
        } else {
          return (
            <SuccessErrorModalBodyTemplate
              transactionType={TransactionType.SUCCESS}
              modalTitle={getSuccessMessage(transactionType)}
              modalSubTitle={
                transactionType === TXN_TYPES.UNSTAKE && daysToWaitForWithdraw
                  ? `Unstaking will take ${daysToWaitForWithdraw} days. Please check request status in the withdraw section.`
                  : ""
              }
              primaryBtnTxt="View transaction"
              onSubmitPrimary={handleTxView}
            />
          );
        }
      }
    } else {
      return (
        <SuccessErrorModalBodyTemplate
          isSpinnerRequired={true}
          modalTitle="Please check your wallet"
        />
      );
    }
  };

  return (
    <ModalDrawer isOpen={isOpen} closeAlert={closeAlert}>
      <Center
        padding={{ base: "0 1rem 2.5rem", md: "2.5rem 3rem" }}
        fontWeight="600"
        flexDirection="column"
        width="100%"
      >
        <CloseIcon onClose={closeAlert} />
        {renderContent()}
      </Center>
    </ModalDrawer>
  );
};

export default TransactionModal;
