import { Center, Flex, Text, Link, Box } from "@chakra-ui/react";
import BaseImage from "@components/BaseImage";
import DefiTopOpportunity from "./components/DefiTopOpportunity";

import FooterButtons from "./components/FooterButtons";

interface DepositProps {
  modalTitle: string;
  modalSubTitle?: string;
  primaryBtnTxt?: string; // view transactions
  secondaryBtnTxt?: string; // close or cancel
  onSubmitPrimary?: () => void;
  onSubmitSecondary?: () => void;
  onTxView?: () => void;
}

const VaultDeposit = ({
  modalTitle,
  primaryBtnTxt,
  secondaryBtnTxt,
  onTxView,
  onSubmitPrimary,
  onSubmitSecondary,
}: DepositProps) => {
  return (
    <Center textAlign="center" flexDir="column" w="100%">
      <BaseImage
        src="/assets/transactions/vaultTx.svg"
        width={70}
        height={70}
        alt="icon"
      />
      <Text
        mt="1rem"
        fontSize={{
          base: "20px",
          md: "24px",
        }}
        lineHeight="28px"
      >
        {modalTitle}
      </Text>

      <Flex
        mt="0.5rem"
        fontSize="12px"
        fontWeight="400"
        textAlign="center"
        color="textSecondary"
        gap="4px"
      >
        {/* <Text>{modalSubTitle}</Text> */}
        <Link
          isExternal
          borderBottom="1px dashed"
          borderColor="textSecondary"
          onClick={onTxView}
        >
          View Transaction
        </Link>
      </Flex>

      <Text
        color="textSecondary"
        mt={{ base: "32px", md: "24px" }}
        fontWeight="400"
        fontSize="14px"
        lineHeight="20px"
      >
        Boost your agETH rewards with our DeFi partners.
      </Text>

      <DefiTopOpportunity type="vault" />

      {primaryBtnTxt || secondaryBtnTxt ? (
        <Box>
          <FooterButtons
            secondaryBtnTxt={secondaryBtnTxt}
            onSubmitSecondary={onSubmitSecondary}
            primaryBtnTxt={primaryBtnTxt}
            onSubmitPrimary={onSubmitPrimary}
          />
        </Box>
      ) : null}
    </Center>
  );
};

export default VaultDeposit;
