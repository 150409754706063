import type { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";

import { Provider } from "react-redux";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import Banner from "@components/Banner";

import "../styles/globals.scss";
import "@rainbow-me/rainbowkit/styles.css";

import { getCustomTheme } from "../theme";

import { store } from "../store";
import RudderStackScript from "@components/RudderStackScript";
import AnalyticsMapping from "@constants/analytics_mapping.json";

import WagmiProvier from "@services/WagmiProvider";
// import { useEffect, useState } from "react";

// import useLedgerCookies from "@hooks/useLedgerCookies";

const colors = {
  myScheme: {
    50: "#daffff",
    100: "#b1fbfb",
    200: "#85f7f7",
    300: "#58f3f3",
    400: "#31f0f0",
    500: "#1ed7d7",
    600: "#0ca7a7",
    700: "#007777",
    800: "#004949",
    900: "#001a1a",
  },
};

export const theme = extendTheme({ colors });

const DefaultHead = () => {
  return (
    <>
      <Script
        src="https://attribution.metacrm.inc/tracking-1-1-1.js"
        data-entity-id="653b778fdad2d170612a7af9"
        id="metacrm-tracking"
        integrity="sha384-KVT0cdwbRt/fjdhbbEcHAVvFdtrAkBuvoip0q/+NDjPC73iAPNEkAXLkGOre5AUP"
        crossOrigin="anonymous"
        // @ts-ignore
        apiKey="r7cd59xw9sa"
      />
      <RudderStackScript fileName={AnalyticsMapping["fileName"]} />
      <Head>
        <title>Kelp Liquid restaking | restake ETH, stETH & ETHx</title>
        <meta name="robots" content="index, follow" />
      </Head>
    </>
  );
};

function MyApp({ Component, pageProps }: AppProps) {
  // const [isMounted, setIsMounted] = useState(false);

  // useEffect(() => {
  //   setIsMounted(true);
  // }, []);

  // if (!isMounted) {
  //   return;
  // }

  return (
    <>
      <DefaultHead />
      <ChakraProvider theme={getCustomTheme()}>
        <Banner />
        <Provider store={store}>
          <WagmiProvier>
            <Component {...pageProps} suppressHydrationWarning />
          </WagmiProvier>
        </Provider>
      </ChakraProvider>
    </>
  );
}

export default MyApp;
