import { getExchangeRate } from "services";
import { useSelector } from "react-redux";
import {
  ETH,
  ETHX,
  SFRXETH,
  STETH,
  WETH,
  WSTETH,
} from "@config/contractsConfig";
import { useQuery } from "@tanstack/react-query";

export type ERDataType = {
  exchangeRate: number | undefined;
};

const APPOVED_ERC20 = [ETHX, ETH, STETH, WETH, WSTETH, SFRXETH];

const useExchangeRate = () => {
  const { selectedERC20 } = useSelector((state: any) => state.user);

  const { data: exchangeRateData } = useQuery({
    queryKey: ["exchange-rate", selectedERC20],
    queryFn: async () => {
      const r = await getExchangeRate(selectedERC20);
      return r.data;
    },
    enabled: APPOVED_ERC20.includes(selectedERC20),
    refetchInterval: 10_000,
  });

  return {
    exchangeRate: exchangeRateData?.value,
  };
};

export default useExchangeRate;
