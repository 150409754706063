import { chainDefiSectionMapping } from "@constants/common";
import { DeFiRow } from "@container/pages/defi/type";
import { useMemo } from "react";
import useDappChain from "./useDappChain";
import defiJSON from "@files/defi.json";

enum ASSETS {
  AGETH = "ageth",
}

export enum DEFI_FILTER_VARIANT {
  SUCCESS_MODAL = "SUCCESS_MODAL",
  VAULT_PARTNERSHIPS = "VAULT_PARTNERSHIPS",
}
enum FIELD_TYPE {
  RSETH_DEPOSIT_FEATURED = "rsethDepositFeatured",
  AGETH_DEPOSIT_FEATURED = "agethDepositFeatured",
  AGETH_PARTNERSHIPS = "agethPartnerships",
}

const getFilteredRows = (
  rows: DeFiRow[],
  field: FIELD_TYPE,
  variant: DEFI_FILTER_VARIANT
) => {
  return rows.filter((row) => {
    if (variant === DEFI_FILTER_VARIANT.SUCCESS_MODAL) {
      return row[field]?.toLowerCase() === "yes";
    } else if (variant === DEFI_FILTER_VARIANT.VAULT_PARTNERSHIPS) {
      return row["Token 1 Icon"].toLowerCase() === ASSETS.AGETH;
    }
  });
};

interface UseDefiDataProps {
  type: string;
  variant?: DEFI_FILTER_VARIANT;
}

const useDefiData = ({
  type,
  variant = DEFI_FILTER_VARIANT.SUCCESS_MODAL,
}: UseDefiDataProps) => {
  const { chain } = useDappChain();
  const { pools } = defiJSON;

  const sections = useMemo(() => {
    const sections: { [key: string]: DeFiRow[] } = {};
    pools?.data.forEach((row: any) => {
      if (row.Section) {
        sections[row.Section] = sections[row.Section] || [];
        sections[row.Section].push(row);
      }
    });
    return sections;
  }, [pools?.data]);

  const rows = getFilteredRows(
    sections[chainDefiSectionMapping[chain.id]],
    type === "restake"
      ? FIELD_TYPE.RSETH_DEPOSIT_FEATURED
      : FIELD_TYPE.AGETH_DEPOSIT_FEATURED,
    variant
  );

  if (variant === DEFI_FILTER_VARIANT.SUCCESS_MODAL) {
    return rows?.length > 0 ? rows[0] : null;
  }

  return rows?.length > 0 ? rows : [];
};

export default useDefiData;
