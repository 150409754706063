import { Center, Spinner, Flex, Text, Box, useToast } from "@chakra-ui/react";
import BaseImage from "@components/BaseImage";
import { TransactionType } from "@constants/types";
import { SyntheticEvent, useEffect, useState } from "react";
import SubscribeEmail from "./SubscribeEmail";
import { validateEmail } from "utils";

interface SuccessErrorModalBodyTemplateProps {
  transactionType?: TransactionType;
  isSpinnerRequired?: boolean;
  modalTitle: string;
  modalSubTitle?: string;
  extraText: string;
  onSubscribe: () => void;
}

const VaultWithdraw = ({
  isSpinnerRequired = false,
  transactionType,
  modalTitle,
  modalSubTitle,
  extraText,
  onSubscribe,
}: SuccessErrorModalBodyTemplateProps) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSubscribing, setIsSubscribing] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (email) {
      const isValidEmail = validateEmail(email);

      if (isValidEmail) {
        setError("");
      } else {
        setError("Please enter correct email ID");
      }
    }
  }, [email]);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError("Please enter correct email ID");
      return;
    }
    setIsSubscribing(true);
    try {
      await fetch(
        "https://script.google.com/macros/s/AKfycbwlXo-hnvV4oFOLYm7jvutWJu98XeRvSKTnpMwEHle-iIDDDsDGh33q2VtyNvQrY9b-/exec",
        {
          method: "POST",
          mode: "no-cors",
          redirect: "follow",
          headers: {
            "Content-Type": "text/plain;charset=utf-8",
          },
          body: JSON.stringify(Object.values({ email: email })),
        }
      );

      toast({
        description: "Submitted successfully!",
        status: "success",
        duration: 2000,
        position: "top",
        isClosable: false,
      });
      setEmail("");
      setError("");
      onSubscribe();
    } catch (error) {
      console.error("Error:", error);

      toast({
        description: "An error occurred. Please try again.",
        status: "error",
        duration: 2000,
        position: "top",
        isClosable: false,
      });
    } finally {
      setIsSubscribing(false);
    }
  };

  return (
    <Center textAlign="center" flexDir="column" w="100%">
      {isSpinnerRequired && (
        <Center h="160px" w="160px">
          <Spinner
            size="xl"
            thickness="4px"
            speed="0.75s"
            color="textPrimary"
          />
        </Center>
      )}
      {transactionType && (
        <BaseImage
          src="/assets/transactions/vaultTx.svg"
          width={70}
          height={70}
          alt="icon"
        />
      )}
      <Text
        mt="1rem"
        fontSize={{
          base: "20px",
          md: "24px",
        }}
        lineHeight="28px"
      >
        {modalTitle}
      </Text>

      <Flex
        mt="0.5rem"
        fontSize="12px"
        fontWeight="400"
        textAlign="center"
        color="textSecondary"
        gap="4px"
      >
        <Text>{modalSubTitle}</Text>
      </Flex>

      <Box mt="24px" w="100%">
        <SubscribeEmail
          value={email}
          onChange={(value) => setEmail(value)}
          handleSubmit={handleSubmit}
          isDisabled={isSubscribing}
        />
        {error && (
          <Text textStyle="p14" color="#e53e3e" mt="0.5rem" textAlign="left">
            {error}
          </Text>
        )}
      </Box>

      <Text
        fontSize={{ base: "12px", md: "14px" }}
        fontWeight="400"
        lineHeight={{ base: "16px", md: "20px" }}
        color="textSecondary"
        mt="12px"
      >
        {extraText}
      </Text>
    </Center>
  );
};

export default VaultWithdraw;
