import { Center, Flex, Text, Link, AvatarGroup, Show } from "@chakra-ui/react";
import BaseImage from "@components/BaseImage";
import { DefiImg } from "@components/StorageImage";
import {
  POST_DEPOSIT_DEFI_EXPLORE,
  POST_VAULT_DEFI_EXPLORE,
} from "@constants/analytics";
import { DeFiRow } from "@container/pages/defi/type";
import useDefiData from "@hooks/useDefiData";
import { useMemo } from "react";
import { trackEvent } from "utils";

interface DefiTopOpportunityProps {
  type: "restake" | "vault"; // Deposit = vault
}

const RenderArrow = () => {
  return (
    <Flex maxW="12px">
      <BaseImage
        height="12"
        width="12"
        alt="link"
        src="/assets/link-arrow.svg"
      />
    </Flex>
  );
};

const DefiTopOpportunity = ({ type }: DefiTopOpportunityProps) => {
  const defiData = useDefiData({
    type,
  }) as DeFiRow;

  const tags = useMemo(() => {
    return defiData?.Tags?.split(",").map((tag) => tag.trim()) || [];
  }, [defiData]);

  const defiTokensPair = useMemo(() => {
    if (defiData) {
      return (
        <Flex
          align="flex-start"
          gap="4px"
          alignItems={"center"}
          justifyContent="space-between"
          //   flex={{ base: "none", md: 1.5 }}
        >
          <AvatarGroup size="sm" max={2} spacing="-0.4rem">
            <DefiImg
              img={defiData?.["Token 1 Icon"]}
              height="20px"
              width="20px"
            />
            <DefiImg
              img={defiData?.["Token 2 Icon"]}
              height="20px"
              width="20px"
            />
          </AvatarGroup>
          <Flex direction="column" gap={2}>
            <Flex>{defiData?.Pair || "-"}</Flex>
          </Flex>
        </Flex>
      );
    }
    return null;
  }, [defiData]);

  const defiPlatform = useMemo(() => {
    if (defiData) {
      return (
        <Flex align="center" gap="4px">
          <DefiImg img={defiData?.Provider} height="20px" width="20px" />
          <div>{defiData?.Provider || "-"}</div>
        </Flex>
      );
    }
    return null;
  }, [defiData]);

  return (
    <>
      {defiData ? (
        <Link
          isExternal
          href={defiData["Add Liquidity"]}
          onClick={() => {
            trackEvent(
              type === "restake"
                ? POST_DEPOSIT_DEFI_EXPLORE
                : POST_VAULT_DEFI_EXPLORE,
              {
                chain: defiData.Section,
                activity_type: defiData.Activity,
                defi_protocol: defiData.Provider,
                asset: defiData.Pair,
                cta_location: defiData.Provider,
              }
            );
          }}
          w="100%"
          _hover={{
            textDecoration: "none",
          }}
        >
          <Flex
            p="16px"
            flexDir="row"
            borderRadius="12px"
            alignItems="flex-start"
            bg="backgroundCanvas"
            gap="4px"
            fontSize="14px"
            fontWeight="400"
            my={{ base: "24px", md: "18px" }}
            w="100%"
          >
            <Flex
              flexDir={{ base: "column", md: "row" }}
              gap={{ base: "12px", md: "4px" }}
              w="100%"
            >
              <Show above="md">
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  marginRight="8px"
                >
                  {defiTokensPair}
                  {defiPlatform}

                  <Text fontWeight="600">{`TVL: ${defiData?.TVL || "-"}`}</Text>

                  <Flex gap="4px">
                    {tags && tags?.length > 0 ? (
                      <Center
                        p="6px 10px"
                        bg="#215959"
                        borderRadius="6px"
                        color="white"
                        fontSize="10px"
                        fontWeight="500"
                        lineHeight="12px"
                      >
                        {tags[0]}
                      </Center>
                    ) : null}
                    {tags && tags?.length > 1 ? (
                      <Center
                        p="6px 10px"
                        bg="#74A8A8 "
                        borderRadius="6px"
                        color="white"
                        fontSize="10px"
                        fontWeight="500"
                        lineHeight="12px"
                      >
                        {`+${tags.length - 1}`}
                      </Center>
                    ) : null}
                  </Flex>
                </Flex>
              </Show>
              <Show below="md">
                <Flex
                  gap={{ base: "12px", md: "4px" }}
                  // flex={{ base: "none", md: 1.2 }}
                >
                  {defiTokensPair}

                  {defiPlatform}
                </Flex>

                <Flex gap={{ base: "12px", md: "4px" }} alignItems="center">
                  <Text fontWeight="600">{`TVL: ${defiData?.TVL || "-"}`}</Text>
                  {tags && tags?.length > 0 ? (
                    <Center
                      p="6px 10px"
                      bg="#215959"
                      borderRadius="6px"
                      color="white"
                      fontSize="10px"
                      fontWeight="500"
                      lineHeight="12px"
                    >
                      {tags[0]}
                    </Center>
                  ) : null}
                  {tags && tags?.length > 1 ? (
                    <Center
                      p="6px 10px"
                      bg="#74A8A8 "
                      borderRadius="6px"
                      color="white"
                      fontSize="10px"
                      fontWeight="500"
                      lineHeight="12px"
                    >
                      {`+${tags.length - 1}`}
                    </Center>
                  ) : null}
                </Flex>
              </Show>
            </Flex>
            <RenderArrow />
          </Flex>
        </Link>
      ) : null}
    </>
  );
};

export default DefiTopOpportunity;
